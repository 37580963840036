import React from "react"
import tw from "twin.macro"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import styled from "@emotion/styled"

import Inner from "../components/Inner"
import Button from "../components/Button"
import Image from "../components/Image"

import * as Color from "../utils/color"
import { mobile } from "../utils/breakpoint"

const List = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-auto-rows: 300px;
	grid-auto-flow: row;
	grid-gap: 32px;

	@media ${mobile} {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}

	${Image} {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 8px;
	}
`

const Overlay = styled.div`
	background-color: ${props => (props.overlayColor ? props.overlayColor : "black")};
	opacity: 0.5;
	position: absolute;
	left: 0;
	width: 100%;
	height: calc(100vh - 64px);
	object-fit: cover;
	z-index: -2;
`

const Background = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -3;
`

const Info = styled.div`
	position: absolute;
	color: white;
	width: 80%;
	margin: 0 auto;
	text-align: center;
	align-self: center;
	z-index: 1;
`

const Subtitle = styled.h4`
	${tw`text-xl md:text-2xl mb-4`}
	font-weight: bold;

	color: ${Color.primary};
`

const Detail = styled.p`
	${tw`text-base md:text-lg mb-6`}
`

const Title = styled.h2`
	${tw`text-5xl md:text-6xl leading-none mb-4`}
	font-weight: bold;
`

const Content = styled.div`
	padding: 2em 0;
`

const Hero = styled.div`
	width: 100%;
	height: calc(100vh - 64px);
	display: flex;
	justify-content: center;
`

const Course = styled.div``

const CourseLayout = ({ data }) => {
	const { name, thumbnail, detail, activityPhotos, color, syllabus } = data.strapiCourses

	return (
		<>
			<Course>
				<Hero>
					<Background src={thumbnail.publicURL} />
					<Overlay overlayColor={color} />
					<Info>
						<Title>{name}</Title>
						<Detail>{detail}</Detail>
						<Button
							transparent
							css={css`
								padding: 1em 2em;
							`}
							onClick={() => window.open(syllabus.publicURL)}
							aria-label={"view course" + name.toLowerCase()}
						>
							View Syllabus
						</Button>
					</Info>
				</Hero>
				<Inner>
					<Content>
						<Subtitle>Activities</Subtitle>
						<List>
							{activityPhotos.map((item, index) => {
								return <Image key={index} imageUrl={`${process.env.API_URL}${item.url}`} />
							})}
						</List>
					</Content>
				</Inner>
			</Course>
		</>
	)
}

export const query = graphql`
	query($id: String!) {
		strapiCourses(id: { eq: $id }) {
			name
			detail
			color
			thumbnail {
				publicURL
			}
			activityPhotos {
				url
			}
			syllabus {
				publicURL
			}
		}
	}
`

export default CourseLayout
